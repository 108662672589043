<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'

export default {
  name: 'EventViewAgendas',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Datatable: () => import('@/components/general/Datatable'),
    Modal: () => import('@/components/general/Modal'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      tabLinks: [
        {
          alias: 'next',
          text: 'event.view.agendas:modal.tab.link.next'
        },
        {
          alias: 'past',
          text: 'event.view.agendas:modal.tab.link.past'
        },
        {
          alias: 'inactive',
          text: 'event.view.agendas:modal.tab.link.inactive'
        }
      ],

      event: null,
      tabLinkActive: 'next',
      // availableSessions: [],

      nextAgendasPagination: {
        query: { name: '' },
        filter: {
          event: null,
          active: true,
          is_still_open: 1
        },

        order: {
          start_date: 'DESC'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6,
        is_default_search: true
      },

      pastAgendasPagination: {
        query: { name: '' },
        filter: {
          event: null,
          active: true,
          past_schedules: 1
        },

        order: {
          start_date: 'DESC'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6,
        is_default_search: true
      },

      inactiveAgendasPagination: {
        query: { name: '' },
        filter: {
          event: null,
          active: false
        },

        order: {
          start_date: 'DESC'
        },

        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6,
        is_default_search: true
      },

      nextAgendas: [],
      pastAgendas: [],
      inactiveAgendas: []
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    ...mapGetters([ 'activeModules' ])
    // canWrite () {
    //   return this.getContextPermission('enrollment') === 'write'
    // }
  },

  watch: {
    'nextAgendasPagination.page' () {
      if (this.nextAgendasPagination.actualPage !== this.nextAgendasPagination.page) {
        this.getTabData('next')
      }
    },

    'pastAgendasPagination.page' () {
      if (this.pastAgendasPagination.actualPage !== this.pastAgendasPagination.page) {
        this.getTabData('past')
      }
    },

    'inactiveAgendasPagination.page' () {
      if (this.inactiveAgendasPagination.actualPage !== this.inactiveAgendasPagination.page) {
        this.getTabData('inactive')
      }
    },

    'tabLinkActive' () {
      this.getTabData(this.tabLinkActive)
    }
  },

  created () {
    const eventId = this.$route.params.id

    this.nextAgendasPagination.filter.event = eventId
    this.pastAgendasPagination.filter.event = eventId
    this.inactiveAgendasPagination.filter.event = eventId

    this.attemptGetAgendasList(this.nextAgendasPagination)
      .then(data => {
        this.nextAgendas = data.data
        this.nextAgendasPagination.lastPage = data.lastPage
        this.nextAgendasPagination.actualPage = data.actualPage
      })

    this.changeTab(this.tabLinkActive)
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetAgendasList'
    ]),

    prevPage (pagination) {
      if (pagination.page > 1) {
        pagination.page -= 1
      }
    },

    nextPage (pagination) {
      pagination.page += 1
    },

    firstPage (pagination) {
      pagination.page = 1
    },

    lastPage (pagination) {
      pagination.page = pagination.lastPage
    },

    changeTab (index) {
      this.tabLinkActive = index
    },

    leave () {
      this.$router.push({
        name: 'events.index',
        params: {
          refresh: true
        }
      })
    },

    specificDateFormat (datetime) {
      return format(new Date(datetime), "dd/MM/yyyy ' - ' HH:mm:ss")
    },

    getTabData (tab = 'next') {
      this.setFetching(true)

      const getPagination = () => {
        if (tab === 'next') {
          return this.nextAgendasPagination
        } else if (tab === 'past') {
          return this.pastAgendasPagination
        } else if (tab === 'inactive') {
          return this.inactiveAgendasPagination
        }
      }

      this.attemptGetAgendasList(getPagination())
        .then(data => {
          if (tab === 'next') {
            this.nextAgendas = data.data
            this.nextAgendasPagination.lastPage = data.lastPage
            this.nextAgendasPagination.actualPage = data.actualPage
          } else if (tab === 'past') {
            this.pastAgendas = data.data
            this.pastAgendasPagination.lastPage = data.lastPage
            this.pastAgendasPagination.actualPage = data.actualPage
          } else if (tab === 'inactive') {
            this.inactiveAgendas = data.data
            this.inactiveAgendasPagination.lastPage = data.lastPage
            this.inactiveAgendasPagination.actualPage = data.actualPage
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    }
  }

}
</script>

<template>
  <Modal
    :active="true"
    @close="leave()"
  >
    <div class="modal-form modal-user-enrollment">
      <span class="modal-subtitle">{{ $t('event.view.agendas:modal.subtitle') }}</span>

      <h2 class="modal-title">
        {{ $route.params.name }}
      </h2>

      <Tabs
        slot="tabs"
        :alias-active="tabLinkActive"
        :links="tabLinks"
        dark
        use-alias
        @tabChange="changeTab"
      />

      <template v-if="tabLinkActive === 'next'">
        <Datatable
          v-if="nextAgendas.length > 0"
          :items="nextAgendas"
          class="agendas-list"
          dark
        >
          <template slot="thead">
            <tr>
              <th class="th">
                {{ $t('event.view.agendas:next.tab.header.1') }}
              </th>

              <th
                class="th text-center"
                width="180"
              >
                {{ $t('event.view.agendas:next.tab.header.2') }}
              </th>

              <th
                class="th text-center"
                width="180"
              >
                {{ $t('event.view.agendas:next.tab.header.3') }}
              </th>

              <th
                class="th text-center"
                width="220"
              >
                {{ $t('event.view.agendas:next.tab.header.4') }}
              </th>
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ props.item.name }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ props.item.modality }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ specificDateFormat(props.item.start_date) }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ specificDateFormat(props.item.end_date) }}</span>
              </td>
            </tr>
          </template>
        </Datatable>

        <template v-else>
          <h1 class="no-results">
            {{ $t('event.view.agendas:no.results') }}
          </h1>
        </template>

        <Pagination
          :active-page="nextAgendasPagination.page"
          :page-count="nextAgendasPagination.lastPage"
          dark
          @firstPage="firstPage(nextAgendasPagination)"
          @lastPage="lastPage(nextAgendasPagination)"
          @nextPage="nextPage(nextAgendasPagination)"
          @previousPage="prevPage(nextAgendasPagination)"
        />
      </template>

      <template v-if="tabLinkActive === 'past'">
        <Datatable
          v-if="pastAgendas.length > 0"
          :items="pastAgendas"
          class="agendas-list"
          dark
        >
          <template slot="thead">
            <tr>
              <th class="th">
                {{ $t('event.view.agendas:next.tab.header.1') }}
              </th>

              <th
                class="th text-center"
                width="180"
              >
                {{ $t('event.view.agendas:next.tab.header.2') }}
              </th>

              <th
                class="th text-center"
                width="180"
              >
                {{ $t('event.view.agendas:next.tab.header.3') }}
              </th>

              <th
                class="th text-center"
                width="220"
              >
                {{ $t('event.view.agendas:next.tab.header.4') }}
              </th>
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ props.item.name }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ props.item.modality }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ specificDateFormat(props.item.start_date) }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ specificDateFormat(props.item.end_date) }}</span>
              </td>
            </tr>
          </template>
        </Datatable>

        <template v-else>
          <h1 class="no-results">
            {{ $t('event.view.agendas:no.results') }}
          </h1>
        </template>

        <Pagination
          :active-page="pastAgendasPagination.page"
          :page-count="pastAgendasPagination.lastPage"
          dark
          @firstPage="firstPage(pastAgendasPagination)"
          @lastPage="lastPage(pastAgendasPagination)"
          @nextPage="nextPage(pastAgendasPagination)"
          @previousPage="prevPage(pastAgendasPagination)"
        />
      </template>

      <template v-if="tabLinkActive === 'inactive'">
        <Datatable
          v-if="inactiveAgendas.length > 0"
          :items="inactiveAgendas"
          class="agendas-list"
          dark
        >
          <template slot="thead">
            <tr>
              <th class="th">
                {{ $t('event.view.agendas:next.tab.header.1') }}
              </th>

              <th
                class="th text-center"
                width="180"
              >
                {{ $t('event.view.agendas:next.tab.header.2') }}
              </th>

              <th
                class="th text-center"
                width="180"
              >
                {{ $t('event.view.agendas:next.tab.header.3') }}
              </th>

              <th
                class="th text-center"
                width="220"
              >
                {{ $t('event.view.agendas:next.tab.header.4') }}
              </th>
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ props.item.name }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ props.item.modality }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ specificDateFormat(props.item.start_date) }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ specificDateFormat(props.item.end_date) }}</span>
              </td>
            </tr>
          </template>
        </Datatable>

        <template v-else>
          <h1 class="no-results">
            {{ $t('event.view.agendas:no.results') }}
          </h1>
        </template>

        <Pagination
          :active-page="inactiveAgendasPagination.page"
          :page-count="inactiveAgendasPagination.lastPage"
          dark
          @firstPage="firstPage(inactiveAgendasPagination)"
          @lastPage="lastPage(inactiveAgendasPagination)"
          @nextPage="nextPage(inactiveAgendasPagination)"
          @previousPage="prevPage(inactiveAgendasPagination)"
        />
      </template>
    </div>
  </Modal>
</template>

<style lang="scss">
.no-results {
  text-align: center;
  font-weight: 300;
  margin-top: 40px;
  font-size: 16px;
  color: white;
}
/* .modal-user-enrollment .tabs-link {
  text-transform: none;
}

.tooltip {
  color: #005EB8;
  z-index: 9999!important;
  font-size: 0.85rem;
}

.tooltip p {
  margin-top: 5px;
}

.tooltip.info {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 500px;
  padding: 24px;
}

.tooltip.info.status-tooltip {
  background: white;
  text-align: center;
  padding: 12px 24px;
}

.tooltip .info .tooltip-arrow {
  border-color: #005EB8;
}

.form-submit.button-bars button {
  margin: 0 20px;
}

.canceled-screen {
  width: 440px;
}

.datatable-wrapper.theme-dark .datatable .td:not(:first-child, :last-child) {
  padding-right: 10px;
  padding-left: 10px;
}

.td.status {
  white-space: nowrap;
}

.modal-user-enrollment .td-tag {
  width: 100%;
  font-weight: bold;
}

.modal-user-enrollment .status-tag.td-tag {
  font-weight: bold;
  color: #ffffff;
  border-radius: 0;
  font-size: 12px;
}
.modal-user-enrollment .td-tag.status-canceled,
.modal-user-enrollment .td-tag.status-expired,
.modal-user-enrollment .td-tag.status-dropout,
.modal-user-enrollment .td-tag.status-initial {
  background-color: rgba(255, 255, 255, .5);
}
.modal-user-enrollment .td-tag.status-in_progress {
  background-color: #ffffbe;
}
.modal-user-enrollment .td-tag.status-finished {
  background-color: #beffbe;
}
.modal-user-enrollment .modal-list__subtitle,
.modal-user-enrollment .modal-list__empty-message {
  color: #ffffff;
}
.modal-user-enrollment .profiles-selection {
  margin-top: 60px;
}
.modal-user-enrollment.modal-form .btn-flat {
  margin-bottom: 0;
}
.modal-user-enrollment.modal-form form {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-user-enrollment.modal-form .datatable-wrapper,
.modal-user-enrollment.modal-form form .link-table {
  width: 100%;
  margin-bottom: 60px;
}
.modal-user-enrollment.modal-form .link-table .datatable {
  margin-top: 0;
}
.modal-user-enrollment.modal-form .link-table .datatable .td {
  cursor: pointer;
  border: 0;
}
.modal-user-enrollment.modal-form .link-table .datatable tr {
  background-color: rgba(255, 255, 255, 0);
  transition: 60ms background-color ease;
}

.modal-user-enrollment.modal-form .link-table .datatable tr.active,
.modal-user-enrollment.modal-form .link-table .datatable tr:hover {
  background-color: rgba(255, 255, 255, 1);
}
.modal-user-enrollment.modal-form .link-table .datatable tr.active .icon,
.modal-user-enrollment.modal-form .link-table .datatable tr.active .td-text,
.modal-user-enrollment.modal-form .link-table .datatable tr:hover .td-text {
  color: rgba(0, 0, 0, .8);
}

.modal-user-enrollment.modal-form .link-table .datatable tr.inactive,
.modal-user-enrollment.modal-form .link-table .datatable tr.inactive tr:hover {
  background-color: rgb(142, 142, 142);
  cursor: not-allowed;
}
.modal-user-enrollment.modal-form .link-table .datatable tr.inactive .icon,
.modal-user-enrollment.modal-form .link-table .datatable tr.inactive .td-text,
.modal-user-enrollment.modal-form .link-table .datatable tr:hover .td-text {
  color: rgba(0, 0, 0, .8);
}

.modal-user-enrollment .search-field {
  align-self: flex-start;
}

.canceled-screen .form-label,
.canceled-screen .form-input,
.canceled-screen .form-input-counter {
  color: #ffffff;
}

.canceled-screen .form-item.has-value.has-floating-label .form-label,
.canceled-screen .form-item.has-focus.has-floating-label .form-label {
    color: #ffffff;
}

.logins-list tr:first-child {
  padding-left: 20px;
}

.logins-list th, .logins-list td span {
  padding-top: 10px;
}

.column-20 {
  width: 20%;
} */
</style>
